import { module } from 'modujs';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      var container = '.c-slider_container';
      var items = 1;
      var pagination = false;
      var navigation = false;
      var breakpoints = [];
      var loop = false;
      var slideChange = false;
      var countermax = false;
      var autoplay = false;

      if (this.getData('container')) {
        container = this.getData('container');
      }

      if (this.getData('loop')) {
        loop = true;
      }

      if (this.getData('autoplay')) {
        autoplay = {
          delay: 3000,
          disableOnInteraction: false,
        };
      }

      if (this.getData('items')) {
        items = this.getData('items');
      }

      if (this.getData('pagination')) {
        pagination = {
          el: this.getData('pagination') + '-pagination',
          clickable: true,
        };
      }

      if (this.getData('navigation')) {
        navigation = {
          nextEl: this.getData('navigation') + '-next',
          prevEl: this.getData('navigation') + '-prev',
        };
      }

      if (this.getData('breakpoints')) {
        breakpoints = JSON.parse(this.getData('breakpoints'));
      }

      if(this.getData('countermax')) {
        countermax = this.getData('countermax');
      }

      if(this.getData('counter')) {
        let counter = this.getData('counter');
        slideChange = function(swiper) {
          var index = swiper.activeIndex;
          if(countermax && index > countermax) {
            index = 1;
          }
          $(counter).text(index);
          $('.swiper-counter_progress-bar').html('<svg width="62" height="62" viewBox="0 0 62 62"><circle class="" cx="31" cy="31" r="29"/><circle id="shape" class="another-circle" cx="31" cy="31" r="29" stroke-dasharray="1000" stroke-dashoffset="1000"/></svg>');
        };
      }


      let slider = new Swiper(this.el, {
        autoplay: autoplay,
        loop: loop,
        slidesPerView: parseInt(items),
        // Optional parameters
        direction: 'horizontal',
        autoHeight: false,

        // If we need pagination
        // pagination: pagination,
        pagination: {
          el: '.swiper-pagination',
          type: "custom",
        },

        // Navigation arrows
        navigation: navigation,

        breakpoints: breakpoints,

        watchSlidesProgress: true,

        on: {
          realIndexChange: slideChange,
          // progress: function(swiper, progress) {
            // var elem = $('.swiper-counter_progress-bar'); 
            // var elem = $('.another-circle');
            // var width = 1000;
            // var autoplayTime = 3000 / 100;
            // var id = setInterval(frame, autoplayTime);
            // function frame() {
            //     if (width <= 0) {
            //         clearInterval(id);
            //     } else {
            //         width--;
            //         // elem.css('width', width + '%'); 
            //         elem.css('stroke-dashoffset', width);
            //     }
            // }
            // $('.swiper-counter_progress-bar').html('<svg width="66" height="66" viewBox="0 0 66 66"><circle class="" cx="33" cy="33" r="33"/><circle id="shape" class="another-circle" cx="33" cy="33" r="33" stroke-dasharray="1000" stroke-dashoffset="1000"/></svg>');
          // }
        },
      });

    }
}
