import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                submitAjax: 'submitAjax',
                submit: 'submit',
                append: 'append',
                remove: 'remove',
                toggleLogoInput: 'toggleLogoInput',
                toggleBold: 'toggleBold',
                toggleItalic: 'toggleItalic',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      var $ = jQuery;
      var $msg_error = 'Ce champ est obligatoire';
      var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';

      if(this.getData('form')) {
        const form = this.getData('form');
        $(form).validate({
          ignore: [],
          lang: 'fr',
          errorElement : 'div',
          errorPlacement: function(error, element) {
            var placement = $(element).data('error');
            if (placement) {
              $(placement).append(error);
            } else {
              error.insertAfter(element);
            }
          },
        });
        const required = this.$('required');
        const rules = [];
        required.forEach(function(element) {
          $(element).rules('add', { required: true, messages : { required : $msg_error } });
          if ($(element).attr('type') == 'email') {
            $(element).rules('add', { email: true, messages : { email : $msg_error_email } });
          }
        });

        const equalTo = this.$('equalTo');
        equalTo.forEach(function(element) {
          $(element).rules('add', { equalTo: $(element).attr('data-equalto'), messages : { equalTo : $(element).attr('data-equalto-msg') } });
        });

        const requiredIf = this.$('requiredIf');
        requiredIf.forEach(function(element) {
          $(element).rules('add', { required: function(el) { return $($(element).attr('data-requiredif')).val().length > 0; }, messages : { required : $msg_error } });
        });
      }

      if(jQuery('.switch-tmce').length) {
        setTimeout(() => {
          jQuery('.switch-tmce').trigger('click');
        }, 1000);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(!urlParams.get('init') == 1) {
          window.location.href = window.location+'&init=1';
        }
      }

      $('.editable-content').on('paste', function(e) {
        e.preventDefault();
      });

    }

    submitAjax(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        var data = new FormData(form[0]);

        if(form.find('#texte_ifr').length) { // Recup le html de l'iframe wp_editor pour sauvegarder le derniere version
          var wp_editor_iframe = form.find('#texte_ifr');
          var post_contents = $('#tinymce', wp_editor_iframe.contents())[0].innerHTML;
          data.append('post_content', post_contents);
        }

        if(form.find('#focus').length) { // Recup le html de l'iframe wp_editor pour sauvegarder le derniere version
          data.append('focus', form.find('#focus').html());
        }

        $.ajax({
          url: this.ajaxurl,
          type: 'POST',
          // data: form.serialize(),
          data: data,
          processData: false,
          contentType: false,
          beforeSend: function() {
            form.find('.c-form_action .c-button').addClass('-disabled');
            form.find('.c-form_msg.-save').addClass('u-margin-top-small');
            form.find('.c-form_msg').removeClass('error');
            form.find('.c-form_msg').removeClass('success');
            // form.find('.c-form_msg_wrap').addClass('-popup');
            form.find('.c-form_msg.-save').html('<p>Enregistrement en cours ...</p>').fadeIn(1000);
          },
          success: function( response ){
            // form.find('.c-form_msg').addClass('u-margin-top-small');
            form.find('.c-form_msg').html(response.msg).fadeIn(1000);
            if(response.post_id) {
              form.find('#post_id').val(response.post_id);
            }
            if(response.status == 'success') {
              form.find('.c-form_msg').removeClass('error');
              form.find('.c-form_msg').addClass('success');
              if(response.reset) {
                form[0].reset();
              }
            }else {
              form.find('.c-form_msg').removeClass('success');
              form.find('.c-form_msg').addClass('error');
            }

            if(response.redirectto) {
              setTimeout(() => {
                window.location.href = response.redirectto;
              }, 1500);
            }
          },
          complete: function() {
            form.find('.c-form_action .c-button').removeClass('-disabled');
            //form.find('.c-form_msg_wrap').removeClass('-popup');
          },
        });
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    submit(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        form.submit();
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    append(e) {
      const target = e.currentTarget;
      let container = $(target).data('append');
      let template = $(target).data('template');

      var form = jQuery(target).parents('form');
      let $this = this;

      $.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: {action: 'form_append', template: template},
        success: function( response ){
          $(container).append(response.content);
          $this.call('update', false, 'Scroll'); // update Locomotive Scroll
        },
      });
    }

    remove(e) {
      const target = e.currentTarget;
      let targetContainerName = $(target).data('target');
      let targetContainer = $('[data-remove="' + targetContainerName + '"]');
      let $this = this;
      targetContainer.remove();
      $this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }

    toggleLogoInput(e) {
      $('#logo-input').removeClass('is-hidden');
      $('#logo-preview').addClass('is-hidden');
    }

    toggleBold(e) {
      document.execCommand('bold');
      let text = $(e.currentTarget).parents('.editable-conten_wrap').find('.editable-content').html();
      $('.editable-content').html(text);
    }
    toggleItalic(e) {
      document.execCommand('italic');
      let text = $(e.currentTarget).parents('.editable-conten_wrap').find('.editable-content').html();
      $('.editable-content').html(text);
    }
}
