import { module } from 'modujs';
import 'select2'; // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';


export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          },
          change: {
            file: 'initfile',
            select: 'select',
          }
        }
    }

    init() {
      $('.select2').select2({
        placeholder: 'Choisir',
        language: 'fr',
      });

      $( '.inputfile' ).each( function() {
        var $input	 = $( this ),
        $label	 = $input.next( 'label' ),
        labelVal = $label.html();

        $input.on( 'change', function( e ) {
          var fileName = '';

          if( this.files && this.files.length > 1 )
          fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
          else if( e.target.value )
          fileName = e.target.value.split( '\\' ).pop();

          if( fileName )
          $label.find( '.title' ).html( fileName );
          else
          $label.html( labelVal );
        });

        // Firefox bug fix
        $input
        .on( 'focus', function(){ $input.addClass( 'has-focus' ); })
        .on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
      });
    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    initfile(e) {
      const target = e.currentTarget;
      var $input	 = $( target ),
      $label	 = $input.next( 'label' ),
      labelVal = $label.html();

      var fileName = '';

      if( target.files && target.files.length > 1 )
      fileName = ( target.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', target.files.length );
      else if( target.value )
      fileName = target.value.split( '\\' ).pop();

      if( fileName )
      $label.find( '.title' ).html( fileName );
      else
      $label.html( labelVal );
    }

    select(e) {
      const target = e.currentTarget;
      
      if($(target).val() != '') {
        target.classList.add('is-active');
      }else {
        target.classList.remove('is-active');
      }
    }
}
