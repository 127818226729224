import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                closeInfowindow: 'closeInfowindow',
            },
        }

        this.map = false;
    }

    init() {
        const el = this.$('map');
        // var map = false;
        setTimeout(() => {
            if (google !== undefined) {
                this.map = this.initMap($(el))
            }
        }, 2000);
        document.addEventListener("googlemaps_loaded", function() {
            setTimeout(() => {
                if (google !== undefined && this.map === false) {
                  this.map = this.initMap($(el));
                }
            }, 2000);
        });
    }

    closeInfowindow(e) {
        $('.c-map_container').removeClass('is-open');
    }

    updateMap(categories) {
        let markers = this.map.markers;
        
        markers.forEach(marker => {
            if(categories.length) {
                if(categories.indexOf(marker.id) != -1) {
                    marker.setVisible(true);
                }
                else {          
                    marker.setVisible(false);
                }
            }else{
                marker.setVisible(false);
            }
        })

        this.centerMap(this.map);
    }

    initMap( $el ) {
        let t = this;
        // Find marker elements within map.
        var $markers = $el.find('.marker');
    
        var styles = [
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#F2F2F2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009CB5"
                    },
                    {
                        "lightness": "85"
                    },
                    {
                        "saturation": "-15"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009CB5"
                    },
                    {
                        "saturation": "-15"
                    },
                    {
                        "lightness": "85"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#C2DBE5"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009CB5"
                    },
                    {
                        "gamma": "1.01"
                    }
                ]
            }
        ];
    
        // Create gerenic map.
        var mapArgs = {
            zoom        : $el.data('zoom') || 16,
            mapTypeId   : google.maps.MapTypeId.ROADMAP,
            styles: styles,
            maxZoom: 15
        };
        var map = new google.maps.Map( $el[0], mapArgs );
    
        // Add markers.
        map.markers = [];
        $markers.each(function(){
            t.initMarker( $(this), map );
        });
    
        // Center map based on markers.
        t.centerMap( map );
    
        // Return map instance.
        return map;
    }

    initMarker( $marker, map ) {
        let t = this;
        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
            lat: parseFloat( lat ),
            lng: parseFloat( lng )
        };
    
        var icon = {
            url : '/app/themes/trinity2/public/images/localisation.svg',
            scaledSize: new google.maps.Size(30, 30)
        };
    
        // Create marker instance.
        var marker = new google.maps.Marker({
            position : latLng,
            map: map,
            id: $marker.data('id'),
            icon: icon
        });
    
        // Append to reference for later use.
        map.markers.push( marker );
    
        // If marker contains HTML, add it to an infoWindow.
        // if( $marker.html() ){
    
            // Create info window.
            // var infowindow = new google.maps.InfoWindow({
            //     content: $marker.html()
            // });
    
            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function() {
                // infowindow.open( map, marker );
                map.panTo(marker.getPosition());
                t.openPopup(marker.id);
            });
        // }
    }

    openPopup(id){
        let type, niveau, age = null;
        let t = this;
    
        if($('#clubFiltresForm').length) {
            let form = $('#clubFiltresForm');
            type = form.find('#type').val();
            age = form.find('#age').val();
            niveau = form.find('#niveau').val();
        }
    
        $.ajax({
            url: '/wp/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {action: 'map_infowindow', id: id, type: type, age: age, niveau: niveau},
            success: function( response ){
                $('.c-map_infowindow').html(response.content);
                $('.c-map_container').addClass('is-open');
                setTimeout(() => {
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                }, 1000);
            },
        });
    }

    centerMap( map ) {

        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function( marker ){
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });
    
        // Case: Single marker.
        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );
    
        // Case: Multiple markers.
        } else{
            map.fitBounds( bounds );
        }
    }
}
