import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
                toggle: 'toggle',
            }
        }
    }

    init() {

    }

    close() {
        const html = document.querySelector('html');
        const burger = document.querySelector('.c-header_nav_burger');
        const text = burger.querySelector('.-text');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        text.textContent = text.getAttribute('data-text');
    }

    toggle(e) {
        let target = e.currentTarget;
        let id = target.getAttribute('data-id');
        $('.c-nav_primary_sub-item[data-id='+id+']').toggleClass('is-active');
        $(target).toggleClass('is-active');
        
    }
}
