import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';

        this.events = {
          
        };
    }

    init() {
        if(this.getData('formid')) {
            let $this = this;
            let formid = this.getData('formid');
            jQuery.ajax({
              url: this.ajaxurl,
              type: 'POST',
              data: { action: 'load_gravity_form', formid: formid },
              success: function (response) {
                $this.loadFormHtml(response);
                // $this.loadTemplates(response.templates);
                // $this.loadScripts(response.scripts);
              },
            });
        }
    }

    submit(e) {
        const target = e.currentTarget;
        const targetid = '#scrollToForm_'.concat(this.getData('formid'));
        this.call('scrollTo', targetid, 'Scroll');
    }
    
    loadFormHtml(form) {
        $(this.el).html(form);
    }

    loadTemplates(templates) {
        // document.body.innerHTML += templates;
        $(this.el).append(templates);
    }

    loadScripts(scripts) {
        jQuery.each( scripts, function( nfScript ){
            var script = document.createElement('script');
            // note that eval() can be dangerous to use - do your research
            eval( scripts[nfScript].extra.data );
            window.nfFrontEnd = window.nfFrontEnd || nfFrontEnd;
            script.setAttribute('src',scripts[nfScript].src);
            document.head.appendChild(script);
        });
    }
}
