import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            getDirection: true,
            getSpeed: true,
            tablet: {
              smooth: true,
              multiplier: 10,
            },
            smartphone: {
              smooth: true,
              multiplier: 10,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        new ResizeObserver(() => this.scroll.update()).observe(this.el);

        // Disable Locomotive scroll when using arrows on input (ex: autocomplete)
        let locoScroll = this.scroll;
        $(':input')
        .keydown(function (e) {
          if (e.which == 38 || e.which == 40) {
            locoScroll.stop();
          }
        })
        .keyup(function (e) {
          locoScroll.start();
        });


        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
            
            if(func == 'animateNumber') {
              $(obj).prop('Counter',0).animate({
                Counter: $(obj.el).text(),
              }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                  $(obj.el).text(Math.ceil(now).toLocaleString('cs-CZ'));
                },
              });
            }
        });

        // this.$skewedSections = document.querySelectorAll("[data-scroll-skewed]");
        // this.speed = 0;
        // this.lerpedSpeed = 0;
        // this.oldSpeed = 0;
        // this.speedRatio = window.isMobile ? 2 : .15;

        // const t = this;

        // function M(t, e, i) {
        //     return (1 - i) * t + i * e
        // }

        this.scroll.on('scroll', (args) => {
            // console.log(args.scroll);
            if(args.scroll.y > 50) {
              $('.c-header').addClass("-sticky -white");
              $('.c-header').removeClass("-transparent");
              $('.c-header .c-button').addClass('-black');
              $('.c-header .c-button').removeClass('-white');
              $('.c-header_nav_link').addClass('-primary');
              $('.c-header_nav_link').removeClass('-white');
            }else {
              if($('.c-header').attr('class').indexOf('-scroll') != -1) {
                $('.c-header').removeClass("-sticky -white");
                $('.c-header').addClass("-transparent");
                $('.c-header .c-button').removeClass('-black');
                $('.c-header .c-button').addClass('-white');
                $('.c-header_nav_link').removeClass('-primary');
                $('.c-header_nav_link').addClass('-white');
              }
            }

            /* Anim Accueil scroll to */
            if(args.currentElements.scrolling && args.currentElements.scrolling.call == 'scrolling') {
              let progress = args.currentElements.scrolling.progress * 100;
              args.currentElements.scrolling.el.style.height = progress + "%";
            }

            /* Anim Skew on scroll */
            // this.speed = args.speed * this.speedRatio;
            // this.oldSpeed = this.speed;

            // if(isNaN(this.speed) || (Math.ceil(100 * this.lerpedSpeed) / 100 == Math.ceil(100 * this.oldSpeed) / 100)) {
            //   this.lerpedSpeed = M(this.lerpedSpeed, 0, .05);
            // }else {
            //   this.lerpedSpeed = M(this.lerpedSpeed, this.speed, .1);
            // }
            // this.oldSpeed = this.lerpedSpeed;

            // for (var e = 0; e < this.$skewedSections.length; e++) {
            //     var i = this.$skewedSections[e], n = this.lerpedSpeed > 0 ? Math.min(this.lerpedSpeed, 1) : Math.max(this.lerpedSpeed, -1);
            //     i.style.transform = "skewY("+n+"deg)";
            // }
        })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target, {
        offset: -150
      });
    }

    update() {
      this.scroll.update();
    }
}
