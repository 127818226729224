import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            mouseover: {
                toggleImage: 'toggleImage',
            }
        }
    }

    init() {
        // if(this.$('toggleImage').length) {
        //     let target = this.$('toggleImage')[0];
        //     console.log('first section', target);
        //     this.toggleImage(target);
        // }
    }

    toggleImage(e) {
        const target = e.currentTarget;
        let image = $(target).attr('data-image');
        let image_title = $(target).attr('data-image-title');
        let container = this.$('container');

        $('.c-card.-projet').removeClass('is-active');
        if(image) {
            $(target).addClass('is-active');
            $(container).html('<figure class="c-figure"><img src="'+image+'" alt="'+image_title+'"/></figure>');
        }else {
            $(target).removeClass('is-active');
            $(container).html('');
        }
    }
}
