import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
            }
        }
    }

    init() {

    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        const text = burger.querySelector('.-text');
        const html = document.querySelector('html');
        
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            burger.classList.remove('is-active');
            text.textContent = text.getAttribute('data-text');
            $('.c-header_nav_link').removeClass('is-hidden');
            $('.c-nav .c-filigrane').removeClass('is-animated');
            $('.c-header').removeClass('-transparent');
            $('.c-header .c-button').addClass('-black');
            $('.c-header .c-button').removeClass('-white');
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
            text.textContent = text.getAttribute('data-textclose');
            $('.c-header_nav_link').addClass('is-hidden');
            $('.c-nav .c-filigrane').addClass('is-animated');
            $('.c-header').addClass('-transparent');
            $('.c-header .c-button').removeClass('-black');
            $('.c-header .c-button').addClass('-white');
        }
    }
}
